import React, { useContext, useEffect, useState } from "react";
import { SessionContext } from "../../contexts/SessionContext";
import LoadingPage from "./LoadingPage";

const LoadingWrapper = ({ children }) => {
  const { isFirstVisit, markAsVisited, loading } = useContext(SessionContext);
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (isFirstVisit) {
      const timer = setTimeout(() => {
        setFadeOut(true);
        const fadeOutTimer = setTimeout(() => {
          setShowLoadingPage(false);
          markAsVisited();
        }, 1000); // Duration of the fade-out animation
        return () => clearTimeout(fadeOutTimer);
      }, 5000); // Adjust the time as per your requirement
      return () => clearTimeout(timer);
    }
  }, [loading, isFirstVisit, markAsVisited]);

  if (showLoadingPage && isFirstVisit) {
    return <LoadingPage fadeOut={fadeOut} />;
  }

  return children;
};

export default LoadingWrapper;
