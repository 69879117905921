import React, { createContext, useState, useEffect } from "react";

const SessionContext = createContext();

const SessionProvider = ({ children }) => {
  const [isFirstVisit, setIsFirstVisit] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const visited = sessionStorage.getItem("visited");

    if (visited) {
      setLoading(false);
    } else {
      setIsFirstVisit(true);
      setLoading(false);
    }
  }, []);

  const markAsVisited = () => {
    sessionStorage.setItem("visited", "true");
    setIsFirstVisit(false);
  };

  return (
    <SessionContext.Provider value={{ isFirstVisit, markAsVisited, loading }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, SessionContext };
