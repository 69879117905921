import React, { useState, useEffect } from "react";
import "./LoadingPage.css";

const LoadingPage = ({ fadeOut }) => {
  const [displayedWords, setDisplayedWords] = useState([]);
  const words = ["Conceive.", "Plan.", "Execute."];
  const [wordIndex, setWordIndex] = useState(0);
  const [logoVisible, setLogoVisible] = useState(false);

  useEffect(() => {
    const logoTimer = setTimeout(() => {
      setLogoVisible(true);
    }, 1000); // 1 second delay for logo fade-in

    return () => clearTimeout(logoTimer);
  }, []);

  useEffect(() => {
    if (logoVisible && wordIndex < words.length) {
      const timer = setTimeout(() => {
        setDisplayedWords((prevWords) => [...prevWords, words[wordIndex]]);
        setWordIndex(wordIndex + 1);
      }, 1000); // 1 second delay for each word after the logo is visible
      return () => clearTimeout(timer);
    }
  }, [logoVisible, wordIndex]);

  return (
    <div className="loading-page">
      <div className={`loading-container ${fadeOut ? "fade-out" : ""}`}>
        <div className="logo-wrapper">
          <img src="assets/img/logo/logo.png" alt="Logo" className="logo" />
        </div>
        <div className="words">
          {words.map((word, index) => (
            <div
              key={index}
              className={`word ${
                displayedWords.includes(word) ? "visible" : ""
              }`}
            >
              {word}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
